<template>
    <div class="tabbar-all">
        <div class="tabbar-all-top">
            <div class="userinfo">
                <img :src="item.headimgurl" alt />
                <div class="tabbar-all-top-l">
                    <p>{{ item.nickname }}</p>
                    <p>订单号:{{ item.order_no }}</p>
                </div>
            </div>
            <div class="tabbar-all-top-r" v-if="from == 'off'">
                <p>核销时间</p>
                <p>{{ item.check_time }}</p>
            </div>
            <div class="tabbar-all-top-r" v-if="from == 'appointment'">
                <p :class="statusInfo.color">{{ statusInfo.text }}</p>
            </div>
        </div>
        <div class="tabbar-all-center">
            <img :src="item.goods_image" alt />
            <div class="tabbar-all-c-r">
                <p class="goods-name">{{ item.goods_name }}</p>
                <p class="spec">{{ item.specs_item_value }}</p>
                <div class="tabbar-all-c-r-b">
                    <p>￥{{ item.goods_price }}</p>
                    <p>x1</p>
                </div>
            </div>
        </div>
        <div class="tabbar-all-order" v-show="isShow">
            <template v-if="from == 'off'">
                <p>姓名：{{item.address_contacts}}</p>
                <p>手机号：{{item.address_phone}}</p>
                <p style="text-decoration: line-through">核销码：{{item.check_code}}</p>
            </template>
            <template v-if="from == 'appointment'">
                <p>姓名：{{item.reserve_user}}</p>
                <p>手机号：{{item.reserve_phone}}</p>
                <p>预约日期：{{ item.reserve_date }}</p>
                <p>预约时间段：{{ item.reserve_time }}</p>
                <p v-if="item.reserve_notes != ''">预约备注：{{ item.reserve_notes }}</p>
            </template>
        </div>
        <div class="switch" @click="changeDown">
            {{ downText }}
            <img src="@/assets/images/qiehuan.png" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        components: {},
        props: {
            item: {
                type: Object,
                default () {
                    return {};
                }
            },
            from: {
                type: String,
                default () {
                    return "";
                }
            }
        },
        data() {
            return {
                downText: "展开",
                isShow: false
            };
        },
        computed: {
            statusInfo() {
                let status = this.item.cards_status;
                switch (status) {
                    case 0:
                        return {
                            text: "已失效", color: "diable"
                        }
                        break;
                    case 1:
                        return {
                            text: "待核销", color: "nouse"
                        }
                        break;
                    case 2:
                        return {
                            text: "已核销", color: "success"
                        }
                        break;
                }
            }
        },
        methods: {
            changeDown() {
                this.isShow = !this.isShow;
                if (this.isShow) {
                    this.downText = "折叠";
                } else {
                    this.downText = "展开";
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .tabbar-all {
        width: 7.05rem;
        background: #fff;
        margin-bottom: 0.3rem;
        border-radius: 0.05rem;
        font-size: 0.26rem;

        .tabbar-all-top {
            display: flex;
            align-items: center;
            padding: 0.14rem 0.12rem 0.17rem 0.12rem;
            border-bottom: 1px solid #eeeeee;
            justify-content: space-between;

            .userinfo {
                display: flex;
            }

            img {
                width: 0.63rem;
                height: 0.63rem;
                border-radius: 50%;
                display: block;
            }

            .tabbar-all-top-l {
                margin-left: 0.16rem;

                p:nth-child(1) {
                    font-size: 0.26rem;
                    color: #000;
                }

                p:nth-child(2) {
                    font-size: 0.21rem;
                    margin-top: 0.1rem;
                }
            }

            .tabbar-all-top-r {
                // margin-left: 2rem;

                p:nth-child(1) {
                    font-size: 0.21rem;
                    color: #666666;

                    &.disable {
                        font-size: 0.24rem;
                        color: #d6d6d6;
                    }

                    &.nouse {
                        font-size: 0.24rem;
                        color: #ff5400;
                    }

                    &.success {
                        font-size: 0.24rem;
                        color: #09ac01;
                    }
                }

                p:nth-child(2) {
                    font-size: 0.18rem;
                    color: #666666;
                    margin-top: 0.1rem;
                }
            }
        }

        .tabbar-all-center {
            width: 100%;
            display: flex;
            padding: 0.2rem 0.1rem 0.2rem 0.1rem;
            box-sizing: border-box;
            border-bottom: 1px solid #eeeeee;

            img {
                width: 2.46rem;
                height: 1.79rem;
            }

            .tabbar-all-c-r {
                margin-left: 0.2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .goods-name {
                    width: 4.2rem;
                    font-size: 0.26rem;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: initial;
                    color: #222;
                }

                .spec {
                    font-size: 0.24rem;
                    color: #999;
                    width: max-content;
                }

                .tabbar-all-c-r-b {
                    width: 4.2rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.23rem;

                    p:nth-child(1) {
                        color: #eb676c;
                        font-size: 0.3rem;
                    }

                    p:nth-child(2) {
                        color: #888888;
                    }
                }
            }
        }

        .tabbar-all-bottom {
            height: 0.97rem;
            line-height: 0.97rem;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            padding-left: 5.4rem;
            box-sizing: border-box;

            p:nth-child(1) {
                font-size: 0.22rem;
                color: #666666;
            }

            p:nth-child(2) {
                font-size: 0.19rem;
                color: #e46d6d;
            }

            p:nth-child(3) {
                font-size: 0.23rem;
                color: #e46d6d;
            }
        }

        .switch {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 0.24rem;
            font-size: 0.24rem;
            color: #adadad;
            line-height: 0.64rem;

            img {
                width: 10px;
                margin-left: 5px;
            }
        }

        .tabbar-all-order {
            margin-top: 0.02rem;
            padding: 0.2rem 0.24rem 0;
            text-align: left;

            p {
                font-size: 0.25rem;
                line-height: 0.48rem;
                color: #2d2d2d;
            }
        }
    }
</style>
